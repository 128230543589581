<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div></div>
        <div class="flex-d-row" style="align-items: center">
          <a-button
            style="margin-right: 15px"
            type="primary"
            @click.prevent="onsave"
          >
            保存
          </a-button>
          <a-icon
            @click="onback"
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <!-- 编辑页 -->
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          margin: 0,
          'min-width': '1300px',
        }"
      >
        <div style="height: 750px" class="flex-d-row">
          <div
            style="
              flex: 1;
              height: 100%;
              border: 1px solid #999;
              border-right: none;
            "
            id="custom-mount"
          ></div>
          <div
            v-if="type == '0'"
            style="
              width: 360px;
              height: 100%;
              border: 1px solid #999;
              overflow-x: hidden;
              overflow-y: auto;
            "
          >
            <a-card
              :headStyle="{ height: '50px', 'font-size': '15px' }"
              title="租赁主体"
              style="width: 100%"
            >
              <div
                class="ul flex-d-row"
                style="flex-wrap: wrap; justify-content: space-between"
              >
                <span class="li" @click="ces('${party_a_name}')">出租方</span>
                <span class="li" @click="ces('${party_a_people}')"
                  >出租方法人/负责人</span
                >
                <span class="li" @click="ces('${party_a_contact}')"
                  >出租方联系电话</span
                >
                <span class="li" @click="ces('${party_a_address}')"
                  >出租方通讯地址</span
                >
                <span class="li" @click="ces('${party_b_name}')">承租方</span>
                <span class="li" @click="ces('${party_b_people}')"
                  >承租方法人/负责人</span
                >
                <span class="li" @click="ces('${party_b_contact}')"
                  >承租方联系电话</span
                >
                <span class="li" @click="ces('${party_b_address}')"
                  >承租方通讯地址</span
                >
              </div>
            </a-card>
            <a-card
              :headStyle="{ height: '50px', 'font-size': '15px' }"
              title="租赁区域"
              style="width: 100%"
            >
              <div
                class="ul flex-d-row"
                style="flex-wrap: wrap; justify-content: space-between"
              >
                <span class="li" @click="ces('${project_name}')">项目名称</span>
                <span class="li" @click="ces('${project_address}')"
                  >项目地址</span
                >
                <span class="li" @click="ces('${store_name}')">铺位名称</span>
                <span class="li" @click="ces('${total_area}')">建筑面积</span>
                <span class="li" @click="ces('${use_area}')">使用面积</span>
                <span class="li" @click="ces('${rent_area}')">计租面积</span>
              </div>
            </a-card>
            <a-card
              :headStyle="{ height: '50px', 'font-size': '15px' }"
              title="租赁用途"
              style="width: 100%"
            >
              <div
                class="ul flex-d-row"
                style="flex-wrap: wrap; justify-content: space-between"
              >
                <span class="li" @click="ces('${business_type}')"
                  >业态类型</span
                >
                <span class="li" @click="ces('${brand_name}')">品牌</span>
              </div>
            </a-card>
            <a-card
              :headStyle="{ height: '50px', 'font-size': '15px' }"
              title="租赁条款"
              style="width: 100%"
            >
              <div
                class="ul flex-d-row"
                style="flex-wrap: wrap; justify-content: space-between"
              >
                <span class="li" @click="ces('${rent_term_m}')"
                  >租赁期限（月）</span
                >
                <span class="li" @click="ces('${rent_term_y}')"
                  >租赁期限（年）</span
                >
                <span class="li" @click="ces('${rent_price}')">租赁价格</span>
                <span class="li" @click="ces('${rent_unit}')"
                  >租赁价格单位</span
                >
                <span class="li" @click="ces('${property_price}')"
                  >物业价格</span
                >
                <span class="li" @click="ces('${property_unit}')"
                  >物业价格单位</span
                >
                <span class="li" @click="ces('${deposit_low}')"
                  >意向金（小写）</span
                >
                <span class="li" @click="ces('${deposit_up}')"
                  >意向金（大写）</span
                >
                <span class="li" @click="ces('${deposit_limit}')"
                  >意向金期限</span
                >
                <span class="li" @click="ces('${intention_limit}')"
                  >意向合同期限</span
                >
              </div>
            </a-card>
          </div>
          <div
            v-else-if="type == '1'"
            style="
              width: 360px;
              height: 100%;
              border: 1px solid #999;
              overflow-x: hidden;
              overflow-y: auto;
            "
          >
            <a-card
              :headStyle="{ height: '50px', 'font-size': '15px' }"
              title="租赁主体"
              style="width: 100%"
            >
              <div
                class="ul flex-d-row"
                style="flex-wrap: wrap; justify-content: space-between"
              >
                <span class="li" @click="ces('${party_a_name}')">出租方</span>
                <span class="li" @click="ces('${party_a_people}')"
                  >出租方法人/负责人</span
                >
                <span class="li" @click="ces('${party_a_contact}')"
                  >出租方联系电话</span
                >
                <span class="li" @click="ces('${party_a_address}')"
                  >出租方通讯地址</span
                >
                <span class="li" @click="ces('${party_b_name}')">承租方</span>
                <span class="li" @click="ces('${party_b_contact}')"
                  >承租方联系电话</span
                >
                <span class="li" @click="ces('${party_b_address}')"
                  >承租方通讯地址</span
                >
                <span class="li" @click="ces('${party_b_name_p}')"
                  >承租方姓名</span
                >
                <span class="li" @click="ces('${party_b_gender_p}')"
                  >承租方性别</span
                >
                <span class="li" @click="ces('${party_b_id_p}')"
                  >承租方证件号</span
                >
                <span class="li" @click="ces('${party_b_name_e}')"
                  >承租方法人名称</span
                >
                <span class="li" @click="ces('${party_b_people_e}')"
                  >承租方法人代表</span
                >
                <span class="li" @click="ces('${party_b_credit_e}')"
                  >承租方营业执照</span
                >
              </div>
            </a-card>
            <a-card
              :headStyle="{ height: '50px', 'font-size': '15px' }"
              title="租赁区域"
              style="width: 100%"
            >
              <div
                class="ul flex-d-row"
                style="flex-wrap: wrap; justify-content: space-between"
              >
                <span class="li" @click="ces('${project_name}')">项目名称</span>
                <span class="li" @click="ces('${project_address}')"
                  >项目地址</span
                >
                <span class="li" @click="ces('${store_name}')">铺位名称</span>
                <span class="li" @click="ces('${total_area}')">建筑面积</span>
                <span class="li" @click="ces('${use_area}')">使用面积</span>
                <span class="li" @click="ces('${rent_area}')">计租面积</span>
                <span class="li" @click="ces('${store_detail}')">铺位详情</span>
              </div>
            </a-card>
            <a-card
              :headStyle="{ height: '50px', 'font-size': '15px' }"
              title="租赁用途"
              style="width: 100%"
            >
              <div
                class="ul flex-d-row"
                style="flex-wrap: wrap; justify-content: space-between"
              >
                <span class="li" @click="ces('${business_type}')"
                  >业态类型</span
                >
                <span class="li" @click="ces('${brand_name}')">品牌</span>
              </div>
            </a-card>
            <a-card
              :headStyle="{ height: '50px', 'font-size': '15px' }"
              title="租赁条款"
              style="width: 100%"
            >
              <div
                class="ul flex-d-row"
                style="flex-wrap: wrap; justify-content: space-between"
              >
                <span class="li" @click="ces('${rent_term_m}')"
                  >租赁期限（月）</span
                >
                <span class="li" @click="ces('${rent_term_y}')"
                  >租赁期限（年）</span
                >
                <span class="li" @click="ces('${rent_start_date}')"
                  >租赁起始日期</span
                >
                <span class="li" @click="ces('${rent_end_date}')"
                  >租赁结束日期</span
                >
                <span class="li" @click="ces('${rent_detail}')">租赁详情</span>
              </div>
            </a-card>
            <a-card
              :headStyle="{ height: '50px', 'font-size': '15px' }"
              title="物业条款"
              style="width: 100%"
            >
              <div
                class="ul flex-d-row"
                style="flex-wrap: wrap; justify-content: space-between"
              >
                <span class="li" @click="ces('${property_price}')"
                  >物业价格</span
                >
                <span class="li" @click="ces('${property_unit}')"
                  >物业价格单位</span
                >
                <span class="li" @click="ces('${rubbish_price}')"
                  >垃圾清运价格</span
                >
                <span class="li" @click="ces('${rubbish_unit}')"
                  >垃圾清运价格单位</span
                >
              </div>
            </a-card>
            <a-card
              :headStyle="{ height: '50px', 'font-size': '15px' }"
              title="保证金条款"
              style="width: 100%"
            >
              <div
                class="ul flex-d-row"
                style="flex-wrap: wrap; justify-content: space-between"
              >
                <span class="li" @click="ces('${surety_detail}')"
                  >保证金详情</span
                >
              </div>
            </a-card>
            <a-card
              :headStyle="{ height: '50px', 'font-size': '15px' }"
              title="优惠条款"
              style="width: 100%"
            >
              <div
                class="ul flex-d-row"
                style="flex-wrap: wrap; justify-content: space-between"
              >
                <span class="li" @click="ces('${discount_detail}')"
                  >优惠详情</span
                >
              </div>
            </a-card>
            <a-card
              :headStyle="{ height: '50px', 'font-size': '15px' }"
              title="账单"
              style="width: 100%"
            >
              <div
                class="ul flex-d-row"
                style="flex-wrap: wrap; justify-content: space-between"
              >
                <span class="li" @click="ces('${bill_term}')">账单周期</span>
                <span class="li" @click="ces('${bill_total_price}')"
                  >账单总额</span
                >
                <span class="li" @click="ces('${bill_detail}')">账单详情</span>
              </div>
            </a-card>
            <a-card
              :headStyle="{ height: '50px', 'font-size': '15px' }"
              title="其他参数"
              style="width: 100%"
            >
              <div
                class="ul flex-d-row"
                style="flex-wrap: wrap; justify-content: space-between"
              >
                <span class="li" @click="ces('${id}')">合同ID</span>
                <span class="li" @click="ces('${code}')">合同编号</span>
                <span class="li" @click="ces('${year}')">年</span>
                <span class="li" @click="ces('${month}')">月</span>
                <span class="li" @click="ces('${day}')">日</span>
                <span class="li" @click="ces('${hour}')">时</span>
                <span class="li" @click="ces('${minute}')">分</span>
                <span class="li" @click="ces('${second}')">秒</span>
              </div>
            </a-card>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import WebOfficeSDK from '../../../assets/js/web-office-sdk-v1.1.14.umd.js'
export default {
  inject: ['reload'],
  data() {
    return {
      Jssdk: '',
      iii: '',
      title: '',
      visb: false,
      values: 1,
      temp: [],
      visible: false,
      type: 0,
    }
  },
  filters: {},
  methods: {
    onsave() {
      const app = this.Jssdk.Application
      // 保存文件的改动
      app.ActiveDocument.Save()
      setTimeout(() => {
        this.$message.success('保存成功')
      }, 200)
      //   console.log(save);
    },
    async ces(e) {
      //   console.log(this.iii);
      const app = this.Jssdk.Application

      // 获取选中区域
      const range = await app.ActiveDocument.Range(
        this.iii.begin,
        this.iii.begin
      )
      // 设置文本
      let a = e.toUpperCase()
      range.Text = a
    },
    onback() {
      this.$router.go(-1)
    },
    async onceshi(urls) {
      let tokens
      if (document.cookie.length > 0) {
        let arr = document.cookie.split('; ') //通过; 将数据转成数组，注意：分号+空格，有空格！！
        for (let i = 0; i < arr.length; i++) {
          let index = arr[i].split('=') //返回第一个“=”所在的位置
          if (index[0] === 'satoken') {
            // name为time的cookie存在
            tokens = index[1]
          }
        }
      }
      const jssdk = WebOfficeSDK.config({
        mount: document.getElementById('custom-mount'),
        refreshToken,
        url: urls,
      })
      const refreshToken = () => {
        //   // 自身业务处理...
        // 可以返回 Promise 或者 return { token, timeout }
        return Promise.resolve({
          token: tokens, // 必需：你需要设置的 toekn
          timeout: 10 * 60 * 1000, //  必需：token 超时时间，以 10 分钟示例 document.getElementById("option")
        })
      }

      // 如果需要对 iframe 进行特殊的处理，可以通过以下方式拿到 iframe 的 dom 对象
      //   console.log(this.Jssdk);
      jssdk.iframe.style = 'width:100%;height:100%'
      // 设置 token
      jssdk.setToken({
        token: tokens, // 根据自身的业务需求，通过异步请求或者模板输出的方式，取得 token
        timeout: 50 * 60 * 1000, // token 超时时间，可配合 refreshToken 配置函数使用，在超时前调用 refreshToken 重新刷新 token
      })
      await jssdk.ready()
      this.Jssdk = jssdk
      this.Jssdk.on('WindowSelectionChange', e => {
        // console.log(e);
        this.iii = e
      })
    },
  },
  mounted() {
    this.type = localStorage.getItem('type')
    let url = localStorage.getItem('wpsurl')
    setTimeout(() => {
      this.onceshi(url)
    }, 100)
  },
  beforeRouteLeave(to, from, next) {
    if (this.Jssdk != '') {
      this.Jssdk.destroy()
    }
    this.$destroy()
    next()
  },
  computed: {},
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.ant-form-item {
  height: 60px;
  margin-bottom: 15px;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
.ul .li {
  list-style: none;
  cursor: pointer;
  margin: 0 0 10px 0;
  padding: 5px;
  border: 1px solid #d9d9d9;
  width: 140px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 43px;
}
</style>
